import { format } from 'date-fns'

export const addColumns = [
  {
    align: 'left',
    name: 'eventIsOpen',
    required: true,
    label: 'Händelse öppen',
    field: 'eventIsOpen',
    sortable: true,
    style: 'width: 0px; min-width: 0px; max-width: 0px',
  },
  {
    align: 'left',
    name: 'missingTrv',
    required: true,
    label: 'Saknas trafikverket',
    field: 'missingTrv',
    sortable: true,
    style: 'width: 0px; min-width: 0px; max-width: 0px',
  },
  {
    align: 'left',
    name: 'eventDate',
    required: true,
    label: 'Händelsedatum',
    field: 'eventDate',
    format: (v: string | null): string =>
      v ? format(new Date(v), 'yyyy-MM-dd') : '',
    sortable: true,
    style: 'width: 0px; min-width: 0px; max-width: 0px',
  },
  {
    align: 'left',
    name: 'eventId',
    required: true,
    label: 'Händelse Id',
    field: 'eventId',
    sortable: true,
    style: 'width: 0px; min-width: 0px; max-width: 0px',
  },
  {
    align: 'left',
    name: 'eventName',
    required: true,
    label: 'Händelse',
    field: 'eventName',
    sortable: true,
  },
]
