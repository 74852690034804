<template>
  <q-btn
    outline
    color="grey-7"
    :label="`${tempPeriod.from} - ${tempPeriod.to}`"
    icon-right="mdi-calendar-range"
    class="q-ml-md"
  >
    <q-menu v-model="showFilterMenu">
      <q-card>
        <q-card-section>
          <div class="row q-col-gutter-md">
            <div class="col-6">
              <q-input
                label="Från"
                type="date"
                dense
                flat
                outlined
                v-model="tempPeriod.from"
                error-message="Måste vara före slutdatum"
              />
            </div>
            <div class="col-6">
              <q-input
                label="Till"
                type="date"
                dense
                flat
                outlined
                v-model="tempPeriod.to"
              />
            </div>
          </div>
          <div class="row text-red text-caption" v-if="!validDate">
            Datum från måste börja innan datum till.
          </div>
          <div class="row text-red text-caption" v-if="!validPeriodLength">
            Perioden får max vara {{ maxDays }} lång
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            label="Hämta"
            :loading="loading"
            :disable="!validDate || !validPeriodLength"
            @click="onFilter"
          />
        </q-card-actions>
      </q-card>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { QForm } from 'quasar'
import { differenceInCalendarDays, isAfter, isEqual } from 'date-fns'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useDeviationCrew } from '@/composable/useDeviationCrew'
import { useDeviationDelay } from '@/composable/useDeviationDelay'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { useDeviationDisruption } from '@/composable/useDeviationDisruption'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'

type DeviationType =
  | 'deviationVehicle'
  | 'deviationCancel'
  | 'deviationCrew'
  | 'deviationDelay'
  | 'deviationDisruption'

export default defineComponent({
  name: 'DeviationFilterModal',

  components: {},

  props: {
    deviationType: {
      type: String as PropType<DeviationType>,
      required: true,
    },
    maxDays: {
      type: Number as PropType<number | null>,
      default: () => null,
    },
  },

  setup(props) {
    const {
      fetchAll: fetchAllProposals,
      fetchCancelProposalsAll,
      loading: loadingStateDeviationCancelProposal,
    } = useDeviationCancelProposal()
    const {
      fetchAll: fetchAllDelayProposals,
      loading: loadingStateDeviationDelayProposal,
    } = useDeviationDelayProposal()

    const {
      fetchAll: fetchAllVehiccleProposals,
      loading: loadingStateDeviationVehicleProposal,
    } = useDeviationVehicleProposal()

    const useDeviation = {
      deviationVehicle: useDeviationVehicle,
      deviationCancel: useDeviationCancel,
      deviationCrew: useDeviationCrew,
      deviationDelay: useDeviationDelay,
      deviationDisruption: useDeviationDisruption,
    }

    const {
      period,
      fetchAll,
      loading: loadingStateDeviation,
    } = useDeviation[props.deviationType]()
    const showFilterMenu = ref(false)
    const formRef = ref<QForm | null>(null)
    const tempPeriod = ref({
      from: period.value.from,
      to: period.value.to,
    })

    const validPeriodLength = computed(() => {
      return props.maxDays !== null
        ? differenceInCalendarDays(
            new Date(tempPeriod.value.to),
            new Date(tempPeriod.value.from)
          ) <= props.maxDays
        : true
    })

    const validDate = computed(() => {
      return (
        isAfter(
          new Date(tempPeriod.value.to),
          new Date(tempPeriod.value.from)
        ) ||
        isEqual(new Date(tempPeriod.value.to), new Date(tempPeriod.value.from))
      )
    })

    async function onFilter() {
      if (!validDate.value || !validPeriodLength.value) return

      period.value.from = tempPeriod.value.from
      period.value.to = tempPeriod.value.to

      const proposalFetch = () => {
        return new Promise(() => {
          if (props.deviationType === 'deviationCancel') {
            return Promise.all([
              fetchAllProposals(period.value),
              fetchCancelProposalsAll(period.value),
            ])
          }

          if (props.deviationType === 'deviationDelay') {
            return fetchAllDelayProposals(period.value)
          }

          if (props.deviationType === 'deviationVehicle') {
            return fetchAllVehiccleProposals(period.value)
          }
        })
      }

      await Promise.all([fetchAll(period.value), proposalFetch()])

      showFilterMenu.value = false
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationCancelProposal.value.getAll ||
        loadingStateDeviation.value.getAll ||
        loadingStateDeviationDelayProposal.value.getAll ||
        loadingStateDeviationVehicleProposal.value.getAll
      )
    })

    return {
      showFilterMenu,
      loading,
      validDate,
      formRef,
      period,
      onFilter,
      tempPeriod,
      validPeriodLength,
    }
  },
})
</script>
