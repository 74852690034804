
import { requiredNotNull } from '@/common/formValidationRules'
import { defineComponent, ref, watch } from 'vue'
import { useVehicleType } from '@/composable/useVehicleType'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import { useSearchTrain } from '@/composable/useSearchTrain'

export default defineComponent({
  name: 'DeviationVehicleTypeInput',

  components: {
    AppFieldSelect,
  },

  props: {
    mode: {
      type: String,
      default: () => 'create',
    },
    modelValue: {
      type: Object,
      required: true,
    },
    disableNewVehicleType: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { fetchAll, data } = useVehicleType()
    const { vehicleComposition } = useSearchTrain()

    function findVehicleTypeUuid(name: string | undefined) {
      if (!name) return null
      return data.value.find((x) => x.name === name)?.uuid || null
    }
    const isFirstSearch = ref(true)
    const model = ref(props.modelValue)

    watch(
      () => vehicleComposition.value.expectedCompositions,
      () => {
        if (isFirstSearch.value && ['update'].includes(props.mode)) {
          isFirstSearch.value = false
          return
        }

        if ('plannedVehicleType1' in model.value) {
          model.value.plannedVehicleType1 = findVehicleTypeUuid(
            vehicleComposition.value.expectedCompositions[0]
          )
        }

        if ('plannedVehicleType2' in model.value) {
          model.value.plannedVehicleType2 = findVehicleTypeUuid(
            vehicleComposition.value.expectedCompositions[1]
          )
        }

        if ('plannedVehicleType3' in model.value) {
          model.value.plannedVehicleType3 = findVehicleTypeUuid(
            vehicleComposition.value.expectedCompositions[2]
          )
        }

        if (props.disableNewVehicleType) return

        if ('newVehicleType1' in model.value) {
          model.value.newVehicleType1 = findVehicleTypeUuid(
            vehicleComposition.value.actualCompositions[0]
          )
        }

        if ('newVehicleType2' in model.value) {
          model.value.newVehicleType2 = findVehicleTypeUuid(
            vehicleComposition.value.actualCompositions[1]
          )
        }

        if ('newVehicleType3' in model.value) {
          model.value.newVehicleType3 = findVehicleTypeUuid(
            vehicleComposition.value.actualCompositions[2]
          )
        }
      },
      {
        deep: true,
        immediate: false,
      }
    )

    fetchAll()

    watch(
      model,
      (value) => {
        emit('update:modelValue', {
          ...props.modelValue,
          newVehicleType1: value.newVehicleType1,
          newVehicleType2: value.newVehicleType2,
          newVehicleType3: value.newVehicleType3,
          plannedVehicleType1: value.plannedVehicleType1,
          plannedVehicleType2: value.plannedVehicleType2,
          plannedVehicleType3: value.plannedVehicleType3,
        })
      },
      { deep: true }
    )

    return {
      model,
      requiredNotNull,
      data,
    }
  },
})
