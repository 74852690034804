<template>
  <q-page>
    <q-dialog v-model="deviationCancelFormModal.state.show" :persistent="false">
      <DeviationCancelFormModal
        show-event-input
        v-if="deviationCancelFormModal.state.show"
      />
    </q-dialog>
    <q-dialog
      v-model="deviationCancelDeleteModal.state.show"
      :persistent="false"
    >
      <DeviationDeleteModal v-if="deviationCancelDeleteModal.state.show" />
    </q-dialog>
    <q-dialog v-model="deviationCancelHideModal.state.show" :persistent="false">
      <DeviationCancelHideModal
        show-event-input
        v-if="deviationCancelHideModal.state.show"
      />
    </q-dialog>
    <DeviationCancelTable
      :addColumns="addColumns"
      :loading="loading"
      height="calc(100vh - 50px - 68px)"
      :deviation-cancels="data"
    >
      <template v-slot:top-left-buttons>
        <h6 class="q-ma-none">Inställda tåg</h6>
        <q-btn
          v-if="can('deviationManagement.deviationCancel.create')"
          icon-right="mdi-plus-circle-outline"
          class="q-ml-md"
          label="Lägg till"
          color="primary"
          @click="() => deviationCancelFormModal.openModal({ mode: 'create' })"
        />
      </template>

      <template v-slot:top-right-buttons>
        <DeviationFilterModal deviation-type="deviationCancel" />
      </template>
    </DeviationCancelTable>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, provide } from 'vue'
import DeviationCancelTable from '@/components/deviation/deviation-cancel/DeviationCancelTable.vue'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationCancelFormModal from '@/components/deviation/deviation-cancel/DeviationCancelFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import DeviationCancelHideModal from '@/components/deviation/deviation-cancel/DeviationCancelHideModal.vue'
import { useProfile } from '@/composable/useProfile'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'CancelDeviations',

  components: {
    DeviationCancelTable,
    DeviationDeleteModal,
    DeviationCancelFormModal,
    DeviationFilterModal,
    DeviationCancelHideModal,
  },

  setup() {
    const { can } = useProfile()
    const {
      loading: loadingStateDeviationCancel,
      fetchAll,
      period,
    } = useDeviationCancel()
    const {
      fetchCancelProposalsAll,
      fetchAll: fetchAllProposals,
      deviationsWithMissingTrv,
      data: deviationCancelProposals,
      loading: loadingStateDeviationCancelProposal,
      loadingGetAll,
    } = useDeviationCancelProposal()
    const deviationCancelFormModal = useModal()
    const deviationCancelDeleteModal = useModal()
    const deviationCancelHideModal = useModal()
    provide('deviation-cancel-form-modal', deviationCancelFormModal)
    provide('deviation-delete-modal', deviationCancelDeleteModal)
    provide('deviation-cancel-hide-modal', deviationCancelHideModal)

    fetchAll(period.value)
    fetchAllProposals(period.value)
    fetchCancelProposalsAll(period.value)

    const loading = computed(() => {
      return (
        loadingStateDeviationCancelProposal.value.getAll ||
        loadingStateDeviationCancel.value.getAll ||
        loadingGetAll.value
      )
    })

    const data = computed(() => {
      if (loading.value) {
        return []
      }

      return [
        ...deviationCancelProposals.value,
        ...deviationsWithMissingTrv.value,
      ]
    })

    return {
      can,
      addColumns,
      data,
      loading,
      deviationCancelFormModal,
      deviationCancelDeleteModal,
      deviationCancelHideModal,
    }
  },
})
</script>
