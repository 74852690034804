
import { computed, defineComponent, provide } from 'vue'
import DeviationCancelTable from '@/components/deviation/deviation-cancel/DeviationCancelTable.vue'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationCancelFormModal from '@/components/deviation/deviation-cancel/DeviationCancelFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import DeviationCancelHideModal from '@/components/deviation/deviation-cancel/DeviationCancelHideModal.vue'
import { useProfile } from '@/composable/useProfile'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'CancelDeviations',

  components: {
    DeviationCancelTable,
    DeviationDeleteModal,
    DeviationCancelFormModal,
    DeviationFilterModal,
    DeviationCancelHideModal,
  },

  setup() {
    const { can } = useProfile()
    const {
      loading: loadingStateDeviationCancel,
      fetchAll,
      period,
    } = useDeviationCancel()
    const {
      fetchCancelProposalsAll,
      fetchAll: fetchAllProposals,
      deviationsWithMissingTrv,
      data: deviationCancelProposals,
      loading: loadingStateDeviationCancelProposal,
      loadingGetAll,
    } = useDeviationCancelProposal()
    const deviationCancelFormModal = useModal()
    const deviationCancelDeleteModal = useModal()
    const deviationCancelHideModal = useModal()
    provide('deviation-cancel-form-modal', deviationCancelFormModal)
    provide('deviation-delete-modal', deviationCancelDeleteModal)
    provide('deviation-cancel-hide-modal', deviationCancelHideModal)

    fetchAll(period.value)
    fetchAllProposals(period.value)
    fetchCancelProposalsAll(period.value)

    const loading = computed(() => {
      return (
        loadingStateDeviationCancelProposal.value.getAll ||
        loadingStateDeviationCancel.value.getAll ||
        loadingGetAll.value
      )
    })

    const data = computed(() => {
      if (loading.value) {
        return []
      }

      return [
        ...deviationCancelProposals.value,
        ...deviationsWithMissingTrv.value,
      ]
    })

    return {
      can,
      addColumns,
      data,
      loading,
      deviationCancelFormModal,
      deviationCancelDeleteModal,
      deviationCancelHideModal,
    }
  },
})
