import { render } from "./CancelDeviations.vue?vue&type=template&id=26720e43"
import script from "./CancelDeviations.vue?vue&type=script&lang=ts"
export * from "./CancelDeviations.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QBtn});
