
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useProfile } from '@/composable/useProfile'
import DeviationSearchTrainInput from '@/components/deviation/DeviationTrainSearchInput.vue'
import DeviationDescriptionInput from '@/components/deviation/DeviationDescriptionInput.vue'
import { DeviationCancel } from '@/types/deviation-cancel'
import DeviationVehicleTypeInput from '@/components/deviation/DeviationVehicleTypeInput.vue'
import DeviationEventInput from '@/components/deviation/DeviationEventInput.vue'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'

export default defineComponent({
  name: 'DeviationCancelFormModal',

  components: {
    DeviationSearchTrainInput,
    DeviationDescriptionInput,
    DeviationVehicleTypeInput,
    DeviationEventInput,
  },

  props: {
    eventUuid: {
      type: String,
    },
    showEventInput: Boolean,
  },

  setup(props) {
    const {
      add: addDeviationCancel,
      update: updateDeviationCancel,
      loading: loadingStateDeviationCancel,
      period,
    } = useDeviationCancel()
    const { fetchAll: fetchAllProposal } = useDeviationCancelProposal()
    const { projectDependentInputFields } = useProfile()
    const formModal = inject<UseModal<Partial<DeviationCancel>>>(
      'deviation-cancel-form-modal'
    )
    const formRef = ref<QForm | null>(null)
    // const $q = useQuasar()

    const createModel = () => ({
      uuid: uuidv4(),
      departureDate: new Date(),
      departure: new Date(),
      arrival: new Date(),
      advertised: null,
      from: '',
      to: '',
      description: '',
      eventUuid: props.eventUuid || null,
      newVehicleType1: null,
      newVehicleType2: null,
      newVehicleType3: null,
      plannedVehicleType1: null,
      plannedVehicleType2: null,
      plannedVehicleType3: null,
      eventName: null,
      eventId: null,
      eventIsOpen: null,
      eventDate: null,
    })

    const model = ref<Partial<DeviationCancel>>(createModel())

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        model.value.eventName = formModal.state.data.eventName
        model.value.uuid = formModal.state.data.uuid
        model.value.departureDate = formModal.state.data.departureDate
        model.value.departure = formModal.state.data.departure
        model.value.arrival = formModal.state.data.arrival
        model.value.advertised = formModal.state.data.advertised
        model.value.from = formModal.state.data.from
        model.value.to = formModal.state.data.to
        model.value.description = formModal.state.data.description || ''
        model.value.eventUuid = formModal.state.data.eventUuid
        model.value.newVehicleType1 = formModal.state.data.newVehicleType1
        model.value.newVehicleType2 = formModal.state.data.newVehicleType2
        model.value.newVehicleType3 = formModal.state.data.newVehicleType3
        model.value.plannedVehicleType1 =
          formModal.state.data.plannedVehicleType1
        model.value.plannedVehicleType2 =
          formModal.state.data.plannedVehicleType2
        model.value.plannedVehicleType3 =
          formModal.state.data.plannedVehicleType3

        model.value.eventId = formModal.state.data.eventId
        model.value.eventIsOpen = formModal.state.data.eventIsOpen
        model.value.eventDate = formModal.state.data.eventDate
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create-proposal'
      ) {
        model.value.advertised = formModal.state.data.advertised
        model.value.departureDate = formModal.state.data.departureDate
        model.value.from = formModal.state.data.from
        model.value.to = formModal.state.data.to
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create' &&
        formModal.state.data.departureDate
      ) {
        model.value.departureDate = formModal.state.data.departureDate
      }
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return Promise.reject()

      if (['create', 'create-proposal'].includes(formModal?.state.mode || '')) {
        await addDeviationCancel(model.value)
        if (formModal?.state.mode === 'create-proposal') {
          await fetchAllProposal(period.value)
          formModal?.closeModal()
          return
        }

        // $q.notify({
        //   message: 'Avvikelsen är skapad',
        //   icon: 'mdi-checkbox-marked-circle-outline',
        //   timeout: 3000,
        //   color: 'primary',
        //   textColor: 'white',
        //   position: 'center',
        //   actions: [{ label: 'Ok', color: 'white' }],
        // })

        formModal?.closeModal()
      }

      if (formModal?.state.mode === 'update') {
        await updateDeviationCancel(model.value)
        formModal?.closeModal()
      }
    }

    const saveAndCreateNew = async () => {
      await onSubmit().then(() => {
        window.setTimeout(() => {
          if (formModal) {
            formModal.openModal({
              mode: 'create',
              data: Object.assign({}, model.value),
            })
          }
        }, 100)
      })
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationCancel.value.create ||
        loadingStateDeviationCancel.value.update
      )
    })

    return {
      projectDependentInputFields,
      loading,
      model,
      formModal,
      formRef,
      onSubmit,
      saveAndCreateNew,
    }
  },
})
