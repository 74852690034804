
import { computed, defineComponent, PropType } from 'vue'
import DeviationTable from '@/components/deviation/DeviationTable.vue'
import QTable from 'quasar/src/components/table/QTable.js';
import format from 'date-fns/format'
import { DeviationCancel } from '@/types/deviation-cancel'
import { useProfile } from '@/composable/useProfile'
import { useVehicleType } from '@/composable/useVehicleType'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'

export default defineComponent({
  name: 'DeviationCancelTable',

  components: {
    DeviationTable,
  },

  props: {
    addColumns: {
      type: Array as PropType<Required<QTable>['columns']>,
      default: () => [],
    },
    loading: Boolean,
    height: {
      type: String,
      default: () => 'calc(100vh - 140px - 166px)',
    },
    deviationCancels: {
      type: Array as PropType<DeviationCancel[]>,
      required: true,
    },
  },

  setup(props) {
    const { projectDependentInputFields } = useProfile()
    const {
      fetchAll,
      loading: loadingVehiceType,
      data: vehicleTypes,
    } = useVehicleType()

    fetchAll()

    function getVehicleTypeName(vehicleTypeUuid: string) {
      return (
        vehicleTypes.value.find(
          (vehicleType) => vehicleType.uuid === vehicleTypeUuid
        )?.name || '-'
      )
    }

    const columns = computed(() => {
      let defaultColumns: QTable['columns'] = [
        ...(props?.addColumns || []),
        {
          align: 'left',
          name: 'advertised',
          required: true,
          label: 'Tåguppdrag',
          field: 'advertised',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departureDate',
          required: true,
          label: 'Avgångsdatum',
          field: 'departureDate',
          format: (v: string) => v && format(new Date(v), 'yyyy-MM-dd'),
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'from',
          required: true,
          label: 'Från plats',
          field: 'from',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'to',
          required: true,
          label: 'Till plats',
          field: 'to',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departure',
          format: (v: string) => v && format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Avg.',
          field: 'departure',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'arrival',
          format: (v: string) => v && format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Ank.',
          field: 'arrival',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
      if (projectDependentInputFields.value.vehicleChange) {
        defaultColumns.push({
          align: 'left',
          name: 'vehicleChange',
          required: true,
          label: 'Förändring antal fordon',
          field: 'vehicleChange',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        })
      }

      if (projectDependentInputFields.value.vehicleType) {
        defaultColumns = [
          ...defaultColumns,
          {
            align: 'left',
            name: 'plannedVehicleType1',
            required: true,
            label: 'Ord. M1',
            field: 'plannedVehicleType1',
            format: getVehicleTypeName,
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedVehicleType2',
            required: true,
            label: 'Ord. M2',
            format: getVehicleTypeName,
            field: 'plannedVehicleType2',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedVehicleType3',
            required: true,
            label: 'Ord. M3',
            format: getVehicleTypeName,
            field: 'plannedVehicleType3',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
        ]
      }

      const columns: QTable['columns'] = [
        ...defaultColumns,
        {
          align: 'left',
          name: 'description',
          required: true,
          label: 'Kommentar',
          field: 'description',
          sortable: true,
        },
      ]

      return columns
    })

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Inställdatåg',
        table: props.deviationCancels,
        columns: columns.value,
      })
    })

    const loadingState = computed(() => {
      return loadingVehiceType.value.getAll || props.loading
    })

    return { exportData, columns, loadingState }
  },
})
