<template>
  <q-card style="width: 420px">
    <q-form ref="formRef" @submit.prevent="onSubmit" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">
        Dölj inställda tåg förslag
      </q-card-section>

      <q-separator />

      <q-card-section>
        <p>Bekräfta dölj förslag</p>
        <q-input
          dense
          square
          outlined
          placeholder="Kommentar..."
          type="textarea"
          autogrow
          :rules="[requiredRule]"
          v-model="comment"
          :disable="loading"
        />
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          :disable="loading"
          @click="formModal.closeModal"
          type="button"
        />
        <q-btn
          label="Bekräfta"
          type="submit"
          color="primary"
          :loading="loading"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { hideDeviationCancelProposal } from '@/api/deleted-deviation-cancel-proposal/hideDeviationCancelProposal'
import { DeviationCancelProposal } from '@/types/deviation-cancel-proposal'
import { v4 as uuidv4 } from 'uuid'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'

export default defineComponent({
  name: 'DeviationCancelHideModal',

  setup() {
    const formModal = inject<UseModal<DeviationCancelProposal>>(
      'deviation-cancel-hide-modal'
    )
    const { data: deviationCancelProposals } = useDeviationCancelProposal()
    const formRef = ref<QForm | null>(null)
    const loading = ref(false)

    const comment = ref('')

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid || !formModal?.state?.data) return
      loading.value = true

      const body = {
        uuid: uuidv4(),
        advertised: formModal.state.data.advertised,
        departureDate: formModal.state.data.departureDate,
        from: formModal.state.data.from,
        to: formModal.state.data.to,
        comment: comment.value,
      }

      deviationCancelProposals.value = deviationCancelProposals.value.filter(
        (x) =>
          !(
            x.advertised === body.advertised &&
            x.departureDate === body.departureDate &&
            x.from === body.from &&
            x.to === body.to
          )
      )

      await hideDeviationCancelProposal(body)

      loading.value = false
      formModal?.closeModal()
    }

    return {
      formModal,
      formRef,
      onSubmit,
      comment,
      loading,
      requiredRule,
    }
  },
})
</script>
